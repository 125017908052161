import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { ExportButton } from '../../../common/CommonBtn';
import CustomDateRange from '../../../common/DateRange/DateRange';
import SelectBox from '../../../common/SelectBox';
import NumberOfRows from '../../../common/SelectNumberOfRows';
import {
  AdminPermissions,
  operationFilterValues,
  pocketActionValues,
  pocketTransactionStatus,
} from '../../../helpers/constants';
import { exportToExcel } from '../../../utils/exportToExcel';
import { fetchAllPartners } from '../../UnassignedTransactions/_redux/transactionsCrud';
import {
  fetchPocketTransactions,
  searchPocketTransactions,
  updateTransactionWithdrawalStatus,
} from '../_redux/pocketsCrud';
import PocketTransactionTable from './PocketTransactionTable';
import Alert from '../../../common/Alert';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';

const tableHeaders = [
  'Transaction ID',
  'Date Executed',
  'Fund Identifier',
  'Fund Name',
  'User Email',
  'User Phone Number',
  'Partner',
  'Operation Type',
  'Action Type',
  'Original  Amount',
  'Transaction Fees',
  'Transaction percentage',
  'FX fee',
  'FX percentage',
  'FX conversion rate',
  'Settlement Details',
  'Payment Channel',
  'Status Description',
  'Transaction status',
];

const PocketTransactions = () => {
  const [dateFilter, setDateFilter] = useState('');
  const [operation, setOperation] = useState('');
  const [source, setAction] = useState('');
  const [status, setStatus] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const [exportLoading, setExportLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [pocketTransactions, setPocketTransactions] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [dateValues, setDateValues] = useState({});
  const [searchItem, setSearchItem] = useState('');
  const [limit, setLimit] = useState(10);
  const [partners, setPartners] = useState();
  const [partnerId, setPartnerId] = useState('');
  const [open, setOpen] = useState(false);
  const [transactionIds, setTransactionIds] = useState([]);
  const handleChange = (e, id) => {
    if (e.target.checked) {
      !transactionIds.includes(id) &&
        setTransactionIds(transactionIds.concat(id));
    } else {
      setTransactionIds(transactionIds.filter(value => value !== id));
    }
  };

  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const handleAlert = (message, iconType) => {
    Swal.fire({
      icon: iconType,
      position: 'top-end',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      text: message,
      showConfirmButton: false,
      timer: 1200,
    });
  };
  const getPocketTransaction = async (page, limit) => {
    try {
      setLoading(true);
      const res = await fetchPocketTransactions(page, limit);
      if (res.status === 200) {
        setPocketTransactions(res.data.items);
        setTotalRecord(res.data.meta.totalItems);
        setTotalPages(res.data.meta.totalPages);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const searchForPocketTransactions = async (
    kyc,
    userLevel,
    user,
    source,
    status,
    dateValues,
  ) => {
    try {
      if (kyc || userLevel || user || dateValues) {
        setLoadingData(true);
        const res = await searchPocketTransactions(
          operation,
          partnerId,
          user,
          source,
          status,
          null,
          dateValues?.startDate,
          dateValues?.endDate,
          searchItem,
          page,
          limit,
        );
        if (res.status === 200) {
          setPocketTransactions(res.data.items);
          setTotalRecord(res.data.meta.totalItems);
          setTotalPages(res.data.meta.totalPages);
          handleAlert(res.data.message, 'success');
          setLoadingData(false);
        }
      }
    } catch (error) {
      handleAlert(error.response.data.message, 'error');
      setLoading(false);
    }
  };

  const handleChangeLimit = async event => {
    const { value: selectedValue } = event.target;
    setPage(1);
    const res = await searchPocketTransactions(
      operation,
      partnerId,
      userStatus,
      source,
      status,
      null,
      dateValues?.startDate,
      dateValues?.endDate,
      searchItem,
      1,
      selectedValue,
    );
    setLimit(selectedValue);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);
      setPocketTransactions(res.data.items);
    }
  };
  const handleDateChange = event => {
    setDateValues({ ...dateValues, [event.target.name]: event.target.value });
  };
  const handleChangePage = async (event, value) => {
    const res = await searchPocketTransactions(
      operation,
      partnerId,
      userStatus,
      source,
      status,
      null,
      dateValues?.startDate,
      dateValues?.endDate,
      searchItem,
      value,
      limit,
    );

    setPage(value);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setPocketTransactions(res.data.items);
      window.scrollTo({ behavior: 'smooth', top: '0px' });
    }
  };
  useEffect(() => {
    getPocketTransaction(page, limit);
    getPartners();
  }, []);
  const setValue = (val, type, id) => {
    if (type === 'operation') {
      setOperation(val);
    } else if (type === 'partners') {
      setPartnerId(id);
    } else if (type === 'action') {
      setAction(val);
    } else if (type === 'status') {
      setStatus(val);
    }
  };

  const handleSearch = async e => {
    const { value } = e.target;
    setPage(1);
    const res = await searchPocketTransactions(
      operation,
      partnerId,
      userStatus,
      source,
      status,
      null,
      dateValues?.startDate,
      dateValues?.endDate,
      value.replace('+', '%2B'),
      1,
      limit,
    );
    setSearchItem(value);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);
      setPocketTransactions(res.data.items);
    }
  };

  const getPartners = async () => {
    try {
      const res = await fetchAllPartners();
      if (res.status === 200) {
        setPartners(res.data.data);
      }
    } catch (error) {}
  };

  const handleCreatePaymentBlock = async transactionIds => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: `Total number of transactions ${transactionIds.length} \n Approving this action will move transactions to pending`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Approve',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const response = await updateTransactionWithdrawalStatus(
          transactionIds,
        );

        swalWithBootstrapButtons
          .fire('Created!', response.data.message, 'success')
          .then(res => {
            if (res.isConfirmed) {
              getPocketTransaction(page, limit);
              setTransactionIds([]);
            }
          });
      } catch (error) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  return (
    <>
      {loading ? (
        <div className="text-center" style={{ marginTop: '100px' }}>
          <img
            style={{ width: '70px' }}
            src={toAbsoluteUrl('/media/logos/loading.gif')}
            alt={'loading gif'}
          />
        </div>
      ) : (
        <main className="card card-custom card-stretch gutter-b">
          <section
            style={{
              width: '95%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <div className="card-header">
              <div
                className="mt-10"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {alert?.alertMessage && <Alert alert={alert} />}
                <h1>Pocket Transactions</h1>
                <p>Transactions</p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <ExportButton
                    exportDocument={() =>
                      exportToExcel(
                        setExportLoading,
                        'v1/admin/pocket/transactions/download',
                        {
                          ...(partnerId && { partnerId }),
                          ...(searchItem && { search: searchItem }),
                          ...(operation && { operation }),
                          ...(dateValues?.startDate && {
                            startDate: dateValues?.startDate,
                          }),
                          ...(dateValues?.endDate && {
                            endDate: dateValues?.endDate,
                          }),
                        },
                      )
                    }
                    loading={exportLoading}
                    btnText={'Export to Excel'}
                    width="253px"
                    height="45px"
                    marginTop
                  />
                </div>
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                <form
                  className="d-flex flex-wrap"
                  style={{
                    marginTop: '1.5rem',
                    marginBottom: '1.5rem',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <SelectBox
                    name="Operation filters"
                    options={operationFilterValues}
                    width="180px"
                    setValue={setValue}
                    type="operation"
                  />

                  <SelectBox
                    name="Status filters"
                    options={pocketTransactionStatus}
                    width="180px"
                    setValue={setValue}
                    type="status"
                  />

                  <SelectBox
                    name="Action filters"
                    options={pocketActionValues}
                    width="180px"
                    setValue={setValue}
                    type="action"
                  />

                  {partners && (
                    <SelectBox
                      name="Partner Filters"
                      options={partners}
                      width="180px"
                      setValue={setValue}
                      type="partners"
                    />
                  )}

                  <button
                    type="button"
                    style={{
                      width: '180px',
                      height: '45px',
                      borderRadius: '10px',
                      padding: '20px, 148px, 20px, 135px',
                      border: '1px solid #0071CE',
                      color: '#0071CE',
                      background: '#FFFFFF',
                    }}
                    onClick={() => setOpen(true)}
                  >
                    {' '}
                    Date Filters
                  </button>
                </form>
                <form
                  className="d-flex flex-wrap"
                  style={{
                    marginTop: '1.5rem',
                    marginBottom: '1.5rem',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search by name,email or phone number"
                    name="plan"
                    min={0}
                    style={{
                      paddingTop: '20px',
                      paddingBottom: '20px',
                      width: '80%',
                      height: '45px',
                      padding: '12px 20px 12px 20px',
                      borderRadius: '10px',
                      border: '1px solid #0071CE',
                    }}
                    onChange={handleSearch}
                  />
                  {/* </div> */}
                  <button
                    className="btn btn-primary px-6 mb-2 mr-1"
                    onClick={e => {
                      e.preventDefault();
                      searchForPocketTransactions(
                        operation,
                        partnerId,
                        userStatus,
                        source,
                        status,
                        dateValues,
                      );
                    }}
                    style={{
                      color: 'white',
                      background: '#0071CE',
                      width: '165px',
                      border: '1px solid #0071CE',
                      borderRadius: '5px',
                    }}
                  >
                    Search
                    <span className="svg-icon svg-icon-light ml-2">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/General/Search.svg',
                        )}
                        alt="search icon"
                      />
                    </span>
                    {loadingData && (
                      <span className="ml-3 spinner spinner-white" />
                    )}
                  </button>
                </form>
              </div>
            </div>
          </section>
          <section className="card-body mt-3 mb-10 pt-0 pb-3">
            <div className="tab-content">
              <div>
                <table className="table table-responsive table-head-custom table-head-bg  table-border table-vertical-center">
                  <thead>
                    <tr className="text-left text-uppercase">
                      {tableHeaders.map((header, index) => (
                        <th
                          key={index}
                          style={{ minWidth: '100px', textAlign: 'center' }}
                          className="pl-7"
                        >
                          <span style={{ color: '#3699FF' }}> {header} </span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {pocketTransactions.map(eachTransaction => (
                      // eslint-disable-next-line no-unused-expressions
                      <PocketTransactionTable
                        {...eachTransaction}
                        getTransaction={getPocketTransaction}
                        getPartners={getPartners}
                        key={eachTransaction.id}
                        handleChange={handleChange}
                        transactionIds={transactionIds}
                        pending={true}
                        setAlert={setAlert}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
              {totalPages && totalPages > 0 ? (
                <Pagination
                  page={page}
                  count={totalPages}
                  onChange={handleChangePage}
                />
              ) : (
                ''
              )}
              {totalRecord && totalRecord > 10 ? (
                <NumberOfRows
                  handleChangeLimit={handleChangeLimit}
                  totalRecord={totalRecord}
                />
              ) : (
                ''
              )}
            </div>

            <CustomDateRange
              open={open}
              setOpen={setOpen}
              handleDateChange={handleDateChange}
              searchTransaction={searchForPocketTransactions}
              operation={operation}
              partnerId={partnerId}
              userStatus={userStatus}
              dateFilter={dateFilter}
              dateValues={dateValues}
            />
          </section>
        </main>
      )}
    </>
  );
};

export default PocketTransactions;
