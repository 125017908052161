import React from 'react';

function CommonInput({ id, label, value, type, onChange, name }) {
  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '50%',
        gap: '5px',
        justifyContent: 'space-between',
        marginTop: '1.5rem',
        marginBottom: '1.5rem',
        position: 'relative',
      }}
    >
      <label
        style={{
          fontFamily: 'Poppins',
          fontSize: '13px',
          fontWeight: '500',
          lineHeight: '16px',
          letterSpacing: '-0.05em',
          textAlign: 'left',
        }}
        htmlFor={id}
      >
        {label}
      </label>
      <div
        style={{
          position: 'relative',
        }}
      >
        <input
          type={type}
          defaultValue={value}
          id={id}
          name={name}
          onChange={onChange}
          style={{
            width: '90%',
            height: '45.46662521362305px',
            borderRadius: '3.7888855934143066px',
            background: 'white',
            textAlign: 'center',
            border: '0.38px solid #CAE2F6',
            paddingRight: '30px',
          }}
        />
      </div>
    </section>
  );
}

export default CommonInput;
