import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const INVESTOR_STATEMENT_URL = `${REACT_APP_BASE_URL}/v1/admin/transactions/investment-statement`;
const UPDATE_INVESTOR_SENSITIVE_INFO_URL = `${REACT_APP_BASE_URL}/v1/admin`;

const INVESTOR_POCKET_BALANCE_URL = `${REACT_APP_BASE_URL}/v1/admin/pocket`;

const KYC_REJECTION_REASONS_URL = `${REACT_APP_BASE_URL}/v1/admin/investors/kyc-rejection-reasons`;
const ADMIN_CREATE_DIRECT_INVESTMENT_TRANSACTION_URL = `${REACT_APP_BASE_URL}/v1/admin/plan-subscriptions`;
const FETCH_INVESTOR_GOAL_URL = `${REACT_APP_BASE_URL}/v1/admin/goals/investor`;
const ADMIN_CREATE_GOAL_TRANSACTION = `${REACT_APP_BASE_URL}/v1/admin/goals`;
const getInvestorStatement = async (dateValues, id) => {
  const res = await axios.get(
    `${INVESTOR_STATEMENT_URL}/${id}?startDate=${dateValues.startDate}&endDate=${dateValues.endDate}
    `,
    {
      responseType: 'arraybuffer',
    },
  );
  return res;
};

const getInvestorPocketBalance = async id => {
  const res = await axios.get(`${INVESTOR_POCKET_BALANCE_URL}/${id}`);
  return res;
};

const getKycRejectionReasons = async () => {
  return axios.get(KYC_REJECTION_REASONS_URL);
};

const adminCreateTransaction = async (id, data) => {
  const res = await axios.post(
    `${ADMIN_CREATE_DIRECT_INVESTMENT_TRANSACTION_URL}/${id}/top-up`,
    data,
  );
  return res;
};

const adminCreateGoalTransaction = async (id, data) => {
  const res = await axios.post(
    `${ADMIN_CREATE_GOAL_TRANSACTION}/${id}/top-up`,
    data,
  );
  return res;
};

const getInvestorGoals = async id => {
  const res = await axios.get(`${FETCH_INVESTOR_GOAL_URL}/${id.toString()}`);
  return res;
};

const updateInvestorSensitiveInfo = async (id, data) => {
  const res = await axios.put(
    `${UPDATE_INVESTOR_SENSITIVE_INFO_URL}/investors/${id}/account-info`,
    data,
  );
  return res;
};

export {
  getInvestorStatement,
  getInvestorPocketBalance,
  adminCreateTransaction,
  adminCreateGoalTransaction,
  getInvestorGoals,
  getKycRejectionReasons,
  updateInvestorSensitiveInfo,
};
