import React from 'react';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import CommonInput from '../../../common/CommonInput';
import DisabledInput from '../../../common/DisabledInput';
import HighlightedHeader from '../../../common/FormDisplay/HighlightedHeader';
import { AdminPermissions } from '../../../helpers/constants';

function FormDisplayWithButton({ name, items, onSubmit, loadingData }) {
  return (
    <>
      <HighlightedHeader name={name} />
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
        className="d-flex flex-wrap "
        style={{
          width: '75%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {items?.map(item =>
          item?.enabled &&
          grantPermission(AdminPermissions.MANIPULATE_USER_SENSITIVE_INFO) ? (
            <CommonInput
              key={item.id}
              id={item.id}
              label={item.label}
              value={item.value}
              type={item.type}
              onChange={item?.onChange}
              name={item?.name}
            />
          ) : (
            <DisabledInput
              key={item.id}
              id={item.id}
              label={item.label}
              value={item.value}
              type={item.type}
              kycMatch={item.kycMatch}
            />
          ),
        )}
        {grantPermission(AdminPermissions.MANIPULATE_USER_SENSITIVE_INFO) && (
          <div
            className="mt-15 font-weight-bold text-center"
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexBasis: '50%',
              gap: '5px',
              justifyContent: 'space-between',
              marginTop: '1.5rem',
              marginBottom: '1.5rem',
              position: 'relative',
            }}
          >
            <button
              type="submit"
              className="btn  text-white  font-weight-bold px-15 py-4"
              style={{
                background: '#0071CE',
                width: '60%',
                paddingRight: '30px',
                margin: 'auto',
              }}
            >
              Save
              {loadingData && <span className="ml-3 spinner spinner-white" />}
            </button>
          </div>
        )}
      </form>
    </>
  );
}

export default FormDisplayWithButton;
